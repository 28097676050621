import { Artist } from "../entities/Artist/Artist";
import { ArtistRepository } from "../entities/Artist/ArtistRepository";

export interface ArtistService {
	GetItems(): Promise<Artist[]>;
}

export class ArtistServiceImpl implements ArtistService {
	artistRepo: ArtistRepository;

	constructor(ir: ArtistRepository) {
		this.artistRepo = ir;
	}

	async GetItems(): Promise<Artist[]> {
		return this.artistRepo.GetItems();
	}
}
