import { useState, useEffect } from 'react';
import { Box, Flex, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import ArtistList from '../app/Artist/ArtistList';
import NavBar from '../components/NavBar/NavBar';
import ContentBox from '../components/Utils/ContentBox';
import Dimensions from '../constants/Dimensions';

export default function ContactPage({...props}) {
    const [state, setState] = useState();

    useEffect(() => {
    }, []);

    return (
        <Box>
            <NavBar/>
            <ContentBox /*innerBg={'rgba(255,255,255,0.03)'}*/ mt={Dimensions.NavBar.height}>
			    <Text fontSize={'35px'} color={useColorModeValue('white', 'black')}>Contact</Text>
            </ContentBox>
        </Box>
    );
};