import React from "react";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Routes, Route, BrowserRouter } from "react-router-dom";

//EXAMPLES
import items from "./app/Item/Item.reducers";
import videos from "./app/Video/Video.reducers";
import musics from "./app/Music/Music.reducers";
import artists from "./app/Artist/Artist.reducers";

//PAGES
import ArtistsPage from "./pages/ArtistsPage";
import HomePage from "./pages/HomePage";
import VideosPage from "./pages/VideosPage";
import NewsPage from "./pages/NewsPage";
import CommunityPage from "./pages/CommunityPage";
import ContactPage from "./pages/ContactPage";

// Setup Redux store with Thunks 
const reducers = combineReducers({ items, videos, musics, artists });
const store = createStore(reducers, applyMiddleware(thunk));

function App() {
    return (
        <Provider store={store}>
            {/*<VideosList/>
            <MusicList/>*/}
            <BrowserRouter>
				<Routes>
					<Route path="/" element={<ArtistsPage/>} />
					<Route path="/artists" element={<ArtistsPage/>} />
                    <Route path="/videos" element={<VideosPage/>} />
                    <Route path="/news" element={<NewsPage/>} />
                    <Route path="/community" element={<CommunityPage/>} />
                    <Route path="/contact" element={<ContactPage/>} />
				</Routes>
			</BrowserRouter>
            {/*<ArtistsPage/>*/}
        </Provider>
    );
}

export default App;
