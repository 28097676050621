import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { refreshList } from "./Video.actions";
import { Box, Button, Flex, Stack, Text, useBreakpointValue, useColorMode, useColorModeValue } from '@chakra-ui/react'
import ContentBox from "../../components/Utils/ContentBox";
import Dimensions from "../../constants/Dimensions";
import VideoCard from "../../components/VideoCard";

function VideoList({ videos, refreshList }) {
	
	const NUMBER_OF_COLUMNS = useBreakpointValue({base: 1, sm: 2, md: 3, lg: 3});
	const [videoGrouped, setVideoGrouped] = useState([]);

	useEffect(() => {
		createGroups();
    }, [videos, NUMBER_OF_COLUMNS]);

	function createGroups(){
		let video_group = [];
		let video_grouped = [];
		setVideoGrouped(video_grouped);
		let i = 0;

		for(const key in videos) {
			if(video_group.length < (NUMBER_OF_COLUMNS - 1) && ((Object.keys(videos).length - 1) > i)){
				video_group.push(videos[key]);
			} else{
				video_group.push(videos[key]);
				video_grouped.push(video_group);
				setVideoGrouped(video_grouped);
				video_group = [];
			}
			++i;
		}
	}

	return(
		<ContentBox /*innerBg={'rgba(255,255,255,0.03)'}*/ mt={Dimensions.NavBar.height}>
			<Button onClick={refreshList} bg={"transparent"} color={useColorModeValue('white', 'black')} opacity={0.02}>Videos</Button>
			<Text fontSize={'35px'} color={useColorModeValue('white', 'black')}>Videos</Text>
			<Box py={'16px'}>
				{videoGrouped.length > 0 ?
					videoGrouped.map((group, index_group) => {
						return(
							<Stack direction={'row'} spacing={'10px'} key={'video_group_' + index_group}>
								{group.map((video, index_video) => {
									return(
										<VideoCard
											key={'video_blank_' + index_group + '_' + index_video}
											id={video.id}
											name={video.name}
											imageURL={video.imageURL}
											web={video.web}
											rrss={video.rrss}
										/>
									)
								})}
								{group.length < NUMBER_OF_COLUMNS ?
									[...Array(NUMBER_OF_COLUMNS - group.length)].map((item, index) => {
										return(
											<Flex key={'video_blank_' + index} flex={1}/>
										)
									})
								: null}
							</Stack>
						)
					})
				: null}
			</Box>
		</ContentBox>
	)
};

const mapStateToProps = state => ({
  videos: state.videos.videos
});

const mapDispatchToProps = dispatch => ({
  refreshList: () => dispatch(refreshList)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoList);
