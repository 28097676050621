import { useState, useEffect } from 'react';
import { Box, Flex, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

import ArtistList from '../app/Artist/ArtistList';
import NavBar from '../components/NavBar/NavBar';
import ContentBox from '../components/Utils/ContentBox';
import Dimensions from '../constants/Dimensions';
import VideoList from '../app/Video/VideoList';

export default function VideosPage({...props}) {
    const [state, setState] = useState();

    useEffect(() => {
    }, []);

    return (
        <Box>
            <NavBar/>
            <VideoList/>
        </Box>
    );
};