//Libraries
import { useState, useEffect } from 'react';
import { Flex, Text, Image, AspectRatio, Box, Center, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { HiOutlineArrowSmRight } from 'react-icons/hi';

//Constants

//Components & Screens

//Images & Icons
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs';
import { CgFacebook } from 'react-icons/cg';
import { FaTiktok, FaStore } from 'react-icons/fa';

export default function ArtistCard({...props}) {

    const [state, setState] = useState();
    const BACKGROUND_IMAGE_COLOR = useColorModeValue('rgba(255,255,255,0.1)', 'rgba(0,0,0,0.05)');
    const TEXT_COLOR = useColorModeValue('white', 'black');

    useEffect(() => {
    }, []);

    return (
        <Flex flex={1} key={props.key} role={"group"} direction={"column"}>
            <AspectRatio ratio={1}>
                <Flex bg={BACKGROUND_IMAGE_COLOR}>
                    {props.imageURL ?
                        <Image
                            _groupHover={{transform: "scale(1.05)"}}
                            transition="all 1.1s ease"
                            w={'full'}
                            h={'full'}
                            src={props.imageURL}
                            fit={'cover'}
                        />
                    : null }
                </Flex>
            </AspectRatio>
            <Flex direction={'column'} pt={'10px'} pb={'18px'}>
                <Text fontWeight={800} color={TEXT_COLOR} fontSize={'2xl'} opacity={useBreakpointValue({base: 1, md: 0.4})} _groupHover={{opacity: 1}} transition="all .6s ease">{props.name ?? "Artist"}</Text>
                <Flex direction={'column'} opacity={useBreakpointValue({base: 1, md: 0})} _groupHover={{opacity: 1}} transition="all .6s ease">
                    {props.web ? 
                        <Flex alignItems={'center'} as={'button'} onClick={() => window.open(props.web)}>
                            <Text fontWeight={800} color={TEXT_COLOR} _hover={{textDecor: 'underline'}} transition="all .6s ease">OFFICIAL SITE</Text>
                            <Box mt={"4px"} ml={"2px"}><HiOutlineArrowSmRight color={TEXT_COLOR}/></Box>
                        </Flex>
                    : null}
                    <Flex ml={'-8px'} mt={'10px'}>
                        {props.rrss['instagram'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease" onClick={() => window.open(props.rrss['instagram'])}><BsInstagram color={TEXT_COLOR}/></Box> : null}
                        {props.rrss['twitter'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease"><BsTwitter color={TEXT_COLOR} onClick={() => window.open(props.rrss['twitter'])}/></Box> : null}
                        {props.rrss['facebook'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease"><CgFacebook color={TEXT_COLOR} onClick={() => window.open(props.rrss['facebook'])}/></Box> : null}
                        {props.rrss['youtube'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease"><BsYoutube color={TEXT_COLOR} onClick={() => window.open(props.rrss['youtube'])}/></Box> : null}
                        {props.rrss['tiktok'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease"><FaTiktok color={TEXT_COLOR} onClick={() => window.open(props.rrss['tiktok'])}/></Box> : null}
                        {props.rrss['store'] ? <Box as={'button'} px={'8px'} _hover={{opacity: 0.3}} transition="all .6s ease"><FaStore color={TEXT_COLOR} onClick={() => window.open(props.rrss['store'])}/></Box> : null}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
