import {
  LIST_LOAD_REQUEST,
  LIST_LOAD_SUCCESS,
  LIST_LOAD_FAILURE
} from "./Music.types";

const initialState = {
  loading: false,
  musics: []
};

function musics(state = initialState, action = null) {
  switch (action.type) {
    case LIST_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LIST_LOAD_FAILURE:
      return {
        ...state,
        loading: false
      };

    case LIST_LOAD_SUCCESS:
      return {
        ...state,
        musics: action.payload,
        loading: false
      };

    default:
      return state;
  }
}

export default musics;
