export class Artist {
	id: number;
	name: string;
	imageURL: string;
	web: string | null;
	rrss: object;

	constructor(id: number, name: string, imageURL: string, web: string | null, rrss: object) {
		this.id = id;
		this.name = name;
		this.imageURL = imageURL;
		this.web = web;
		this.rrss = rrss;
	}
}
