//Libraries
import { Flex } from '@chakra-ui/react';

/**
*   Este componente marca el area de ancho de la web
**/

export default function ContentBox({...props}) {
    return (
        <Flex flex={1} w={'full'} justifyContent={"center"} bg={props.bg ?? props.backgroundColor ?? "transparent"} position={props.fixed ? "fixed" : "relative"} zIndex={props.zIndex ?? 0}>
            <Flex flex={1} direction={"column"} maxW={props.maxW ?? props.maxWidth ?? "1280px"} px={{base: "16px", md: "16px"}} {...props} bg={props.innerBg ?? props.innerBackgroundColor ?? "transparent"} zIndex={props.zIndex ?? 0}>
                {props.children}
            </Flex>
        </Flex>
    );
};
