import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_es from './translations/es/translation.json'
import translation_en from './translations/en/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    //lng: "en",
    resources: {
      es:{
        translation: translation_es
      },
      en:{
        translation: translation_en
      },
    }
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
});
export default i18n;