import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { refreshList } from "./Artist.actions";
import { Box, Button, Flex, Stack, Text, useBreakpointValue, useColorMode, useColorModeValue } from '@chakra-ui/react'
import ContentBox from "../../components/Utils/ContentBox";
import ArtistCard from "../../components/ArtistCard";
import Dimensions from "../../constants/Dimensions";

function ArtistList({ artists, refreshList }) {
	
	const NUMBER_OF_COLUMNS = useBreakpointValue({base: 1, sm: 2, md: 3, lg: 3});
	const [artistGrouped, setArtistGrouped] = useState([]);

	useEffect(() => {
		createGroups();
    }, [artists, NUMBER_OF_COLUMNS]);

	function createGroups(){
		let artist_group = [];
		let artist_grouped = [];
		setArtistGrouped(artist_grouped);
		let i = 0;

		for(const key in artists) {
			if(artist_group.length < (NUMBER_OF_COLUMNS - 1) && ((Object.keys(artists).length - 1) > i)){
				artist_group.push(artists[key]);
			} else{
				artist_group.push(artists[key]);
				artist_grouped.push(artist_group);
				setArtistGrouped(artist_grouped);
				artist_group = [];
			}
			++i;
		}
	}

	return(
		<ContentBox /*innerBg={'rgba(255,255,255,0.03)'}*/ mt={Dimensions.NavBar.height}>
			<Button onClick={refreshList} bg={"transparent"} color={useColorModeValue('white', 'black')} opacity={0.02}>Artistas</Button>
			<Text fontSize={'35px'} color={useColorModeValue('white', 'black')}>Artists</Text>
			<Box py={'16px'}>
				{artistGrouped.length > 0 ?
					artistGrouped.map((group, index_group) => {
						return(
							<Stack direction={'row'} spacing={'10px'} key={'artist_group_' + index_group}>
								{group.map((artist, index_artist) => {
									return(
										<ArtistCard
											key={'artist_blank_' + index_group + '_' + index_artist}
											id={artists.id}
											name={artist.name}
											imageURL={artist.imageURL}
											web={artist.web}
											rrss={artist.rrss}
										/>
									)
								})}
								{group.length < NUMBER_OF_COLUMNS ?
									[...Array(NUMBER_OF_COLUMNS - group.length)].map((item, index) => {
										return(
											<Flex key={'artist_blank_' + index} flex={1}/>
										)
									})
								: null}
							</Stack>
						)
					})
				: null}
			</Box>
		</ContentBox>
	)
};

const mapStateToProps = state => ({ artists: state.artists.artists });
const mapDispatchToProps = dispatch => ({ refreshList: () => dispatch(refreshList) });

export default connect(mapStateToProps, mapDispatchToProps)(ArtistList);