import {
  LIST_LOAD_REQUEST,
  LIST_LOAD_SUCCESS,
  LIST_LOAD_FAILURE
} from "./Video.types";
import { VideoServiceImpl } from "../../core/usecases/VideoService";
import { VideoRepositoryImpl } from "../../core/infrastructure/VideoRepositoryImpl";

export const refreshList = async dispatch => {
  dispatch({ type: LIST_LOAD_REQUEST });

  try {
    const videoRepo = new VideoRepositoryImpl();
    const videoService = new VideoServiceImpl(videoRepo);
    const videos = await videoService.GetItems();
    dispatch({ type: LIST_LOAD_SUCCESS, payload: videos });
  } catch (error) {
    dispatch({ type: LIST_LOAD_FAILURE, error });
  }
};
