export class Video {
  /*id: number;
  name: string;
  image: string | null;
  url: string;
  artistName: string;*/
  id: number;
	name: string;
	imageURL: string;
	web: string | null;
	rrss: object;

  /*constructor(id: number, name: string, image: string | null, url: string, artistName: string ) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.url = url;
    this.artistName = artistName;
  }*/
  constructor(id: number, name: string, imageURL: string, web: string | null, rrss: object) {
		this.id = id;
		this.name = name;
		this.imageURL = imageURL;
		this.web = web;
		this.rrss = rrss;
	}
}
