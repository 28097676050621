import { useState, useEffect } from 'react';
import { Box, Flex, Icon, Text, useBreakpointValue } from '@chakra-ui/react';

import ArtistList from '../app/Artist/ArtistList';
import NavBar from '../components/NavBar/NavBar';

export default function ArtistsPage({...props}) {
    const [state, setState] = useState();

    useEffect(() => {
    }, []);

    return (
        <Box>
            <NavBar/>
            <ArtistList/>
        </Box>
    );
};