import { useState, useEffect } from 'react';
import { Flex, Box, Text, Imagen, Button, useColorModeValue, useColorMode, Image, useBreakpointValue } from '@chakra-ui/react';
import ContentBox from '../Utils/ContentBox';
import { useTranslation } from 'react-i18next';

//Constants

//Components & Screens
import i18n from "../../i18n"
import { Routes, Route, BrowserRouter } from "react-router-dom";

//Images & Icons
import { BsApple, BsInstagram, BsTwitter, BsYoutube, BsSpotify, BsSunFill } from 'react-icons/bs';
import { CgFacebook } from 'react-icons/cg';
import { FaTiktok, FaStore, FaApple, FaMoon, FaRegMoon } from 'react-icons/fa';
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import Dimensions from '../../constants/Dimensions';
import LOGO from '../../assets/Cutemobb-Logo.svg';
import LOGO_BLACK from '../../assets/Cutemobb-Logo-black.svg';

export default function NavBar({...props}) {

    const [state, setState] = useState();
    const { t, i18n } = useTranslation();
	const { toggleColorMode, colorMode } = useColorMode()

    const MOON_SIZE = useBreakpointValue({base: '14px', md: '20px'});

    var NAV_ITEMS = [
        {
            label: t('navBar.home'),
            href: '/',
        },
        {
            label: t('navBar.artists'),
            href: '/artists',
        },
        {
            label: t('navBar.videos'),
            href: '/videos',
        },
        {
            label: t('navBar.news'),
            href: '/news',
        },
        {
            label: t('navBar.community'),
            href: '/community',
        },
        {
            label: t('navBar.contact'),
            href: '/contact',
        },
    ];

    var RSS_ITEMS = [
        {
            icon: <BsApple color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon: <BsSpotify color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon: <BsYoutube color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon:  <BsTwitter color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon: <BsInstagram color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon: <CgFacebook color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
        {
            icon: <FaTiktok color={useColorModeValue('white', 'black')} size={'20px'}/>,
            href: 'https://www.apple.com/es/',
        },
    ];

    useEffect(() => {
    }, []);

    return (
        <Flex flex={1} position={'relative'} w={'full'} h={Dimensions.NavBar.height} position={'fixed'} alignItems={"center"} bg={useColorModeValue('rgba(0,0,0,0.6)', 'rgba(255,255,255,0.6)')} shadow={'lg'} transition="all .2s ease" style={{backdropFilter: 'saturate(180%) blur(40px)', WebkitBackdropFilter: 'saturate(180%) blur(40px)'}} zIndex={1} px={'16px'}>
            {/*<Text color={useColorModeValue('white','black')}>Logo</Text>*/}
            <Flex flex={1} direction={'row'} justifyContent={{base: 'center', md: 'flex-start'}} >
                <Flex display={colorMode == 'dark' ? 'none' : 'flex'}>
                    <Image
                        h={'50px'}
                        src={LOGO}
                        objectFit="contain"
                    />
                </Flex>
                <Flex display={colorMode == 'light' ? 'none' : 'flex'}>
                    <Image
                        h={'50px'}
                        src={LOGO_BLACK}
                        objectFit="contain"
                    />
                </Flex>
            </Flex>
            <Flex display={{base: 'none', md: 'flex'}} flex={1}/>
            <Flex d={'row'}>
                <Flex mb={'16px'} display={{base: 'none', md: 'flex'}}>
                    <Flex flex={1}/>
                    {RSS_ITEMS.map((item, index) => {
                        return(
                            <Box as={'button'} px={'16px'} _hover={{opacity: 0.3}} transition="all .6s ease" onClick={() => window.open(item.href)}>
                                {item.icon}
                            </Box>
                        )
                    })}
                </Flex>
                <Flex display={{base: 'none', md: 'flex'}}>
                    {NAV_ITEMS.map((navItem, index) => {
                        return(
                            <NavItem
                                index={index}
                                link={navItem.href}
                                label={navItem.label}
                            />
                        )
                    })}
                </Flex>
            </Flex>
            <Flex position={{base: 'absolute', md: 'relative'}} right={{base: '0px', md: null}} as={'button'} px={'20px'} onClick={toggleColorMode} display={{base: 'flex', md: 'flex'}} _hover={{opacity: 0.5}} transition="all .6s ease">
                {colorMode == 'dark' ?
                    <FaMoon color={'black'} size={MOON_SIZE}/>
                :
                    <FaRegMoon color={'white'} size={MOON_SIZE}/>
                }
            </Flex>
        </Flex>
    );
};

function NavItem({...props}) {
    return(
        <Flex as={'button'} role={'group'} px={'16px'} _hover={{opacity: 0.5}} onClick={() => window.open(props.link, '_self')} transition="all 0.6s ease">
            <Text color={useColorModeValue('white','black')} transition="all .2s ease">{props.label}</Text>
        </Flex>
    );
}

