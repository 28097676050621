import { Video } from "../entities/Video/Video";
import { VideoRepository } from "../entities/Video/VideoRepository";

export interface VideoService {
  GetItems(): Promise<Video[]>;
}

export class VideoServiceImpl implements VideoService {
  videoRepo: VideoRepository;

  constructor(ir: VideoRepository) {
    this.videoRepo = ir;
  }

  async GetItems(): Promise<Video[]> {
    return this.videoRepo.GetItems();
  }
}
