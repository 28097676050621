import { Artist } from "../entities/Artist/Artist";
import { ArtistRepository } from "../entities/Artist/ArtistRepository";

class ArtistDTO {
	id: number = 0;
	name: string = "";
}

export class ArtistRepositoryImpl implements ArtistRepository {
	async GetItems(): Promise<Artist[]> {
		var localArtistList = [
			new Artist(
				0, 
				"Leïti Sène", 
				"https://video-images.vice.com/articles/5d15ba1b2c743e0008c61e32/lede/1561715244241-1_leiti.jpeg?crop=1xw%3A0.4529xh%3B0xw%2C0.1737xh&resize=2000%3A*", 
				"https://www.defjam.com/artists/", 
				{'instagram': 'https://instagram.com', 'twitter': 'https://twitter.com'}
			),
			new Artist(
				1, 
				"Bad Bunny", 
				"https://ca-times.brightspotcdn.com/dims4/default/6ca1ac5/2147483647/strip/true/crop/2240x3360+0+0/resize/840x1260!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fae%2F39%2F0c78428547f6be4e23fdbf2f2e2f%2F488842-la-et-bad-bunny-profile-012.JPG", 
				null, 
				{'instagram': 'https://instagram.com'}
			),
			new Artist(
				2, 
				"Nicky Nicole", 
				"https://i.scdn.co/image/ab6761610000e5ebc4339841ed995cedbc0cff4e", 
				"https://www.defjam.com/artists/", 
				{}
			),
			new Artist(
				3, 
				"Jhay Cortez", 
				"https://pxb.cdn.ciudadanodiario.com.ar/ciudadano/082021/1630420212928/Jhay-Cortez-2021-Press-cr-Universal-Music-Group-1615569844-compressed.jpg", 
				"https://www.defjam.com/artists/", 
				{}
			),
			new Artist(
				4, 
				"ROSALÍA", 
				"https://cdn.businessinsider.es/sites/navi.axelspringer.es/public/styles/bi_1860/public/media/image/2021/05/rosalia-2328565.jpg?itok=8M-8gzu2", 
				"https://www.defjam.com/artists/", 
				{'tiktok': 'https://tiktok.com'}
			),
			new Artist(
				5, 
				"Myke Towers", 
				"https://www.cmtv.com.ar/imagenes_artistas/2653.jpg?Myke%20Towers", 
				"https://www.defjam.com/artists/", 
				{'tiktok': 'https://tiktok.com', 'store': 'https://tickb.it'}
			)
		]

  		return localArtistList;
	}
}
