import {
  LIST_LOAD_REQUEST,
  LIST_LOAD_SUCCESS,
  LIST_LOAD_FAILURE
} from "./Artist.types";

import { ArtistServiceImpl } from "../../core/usecases/ArtistService";
import { ArtistRepositoryImpl } from "../../core/infrastructure/ArtistRepositoryImpl";

export const refreshList = async dispatch => {
	dispatch({ type: LIST_LOAD_REQUEST });

	try {
		const artistRepo = new ArtistRepositoryImpl();
		const artistService = new ArtistServiceImpl(artistRepo);
		const artists = await artistService.GetItems();
		dispatch({ type: LIST_LOAD_SUCCESS, payload: artists });
	} catch (error) {
		dispatch({ type: LIST_LOAD_FAILURE, error });
	}
};
